<template>
  <div :class="className" :style="{ height: height, width: width }"></div>
</template>

<script>
// import echarts from "echarts";
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 1000;
export default {
  props: {
    axisSize: {
      type: Number,
      default: 12
    },
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "320px"
    },
    radius: {
      type: String,
      default: "70%"
    },
    nameFontSize: {
      type: Number,
      default: 30
    },
    axisLabel: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    dataAvg: {
      type: Array,
      default: () => []
    },
    indicator: {
      type: Array,
      default: () => []
    },
    showTooltip: {
      type: Boolean,
      default: () => false
    },
    tooltipPosition: {
      type: Array,
      default: () => [0, 0]
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "item"
        },
        textStyle: {
          fontFamily: "db_heaventregular"
        },
        // visualMap: {
        //   top: "middle",
        //   right: 10,
        //   // color: ['red', 'yellow'],
        //   min: 0,
        //   max: 5,
        //   range: [0, 5],
        //   calculable: true,
        // },
        radar: {
          radius: this.radius,
          center: ["50%", "50%"],
          // startAngle:160,
          nameGap: 14,
          splitNumber: 5,
          scale: false,
          name: {
            textStyle: {
              color: "#353535",
              fontFamily: "db_heaventregular",
              fontSize: this.nameFontSize
            }
          },
          axisLabel: {
            formatter: function(value, index) {
              return value;
            },
            show: this.axisLabel,
            showMaxLabel: false,
            align: "center",
            verticalAlign: "middle",
            margin: 0,
            backgroundColor: "white",
            fontFamily: "db_heaventregular",
            fontSize: this.axisSize,
            verticalAlign: "middle"
          },
          splitArea: {
            areaStyle: {
              color: "white",
              opacity: 1
            }
          },
          splitLine: {
            lineStyle: {
              color: "#21519B"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#21519B"
            }
          },
          shape: "circle",
          indicator: this.indicator
        },
        legend: {
          left: "center",
          bottom: "10",
          data: []
        },
        series: this.getSeries()
      });
    },
    getSeries() {
      let series = [];
      if (this.dataAvg.length > 0) {
        series.push({
          type: "radar",
          tooltip: this.getTooltip("dataAvg"),
          areaStyle: {
            normal: {
              color: "rgba(196, 196, 196, 0.5)"
            }
          },
          lineStyle: {
            normal: {
              color: "#07509C",
              width: 1
            }
          },
          itemStyle: {
            normal: {
              color: "#07509C",
              borderWidth: 2.5
            }
          },
          data: this.dataAvg,
          animationDuration: animationDuration
        });
      }

      if (this.data.length > 0) {
        series.push({
          type: "radar",
          tooltip: this.getTooltip("data"),
          areaStyle: {
            normal: {
              color: "rgba(215, 58, 53, 0.7)"
            }
          },
          lineStyle: {
            normal: {
              color: "#D73A35",
              width: 1
            }
          },
          itemStyle: {
            normal: {
              color: "#D73A35",
              borderWidth: 3.5
            }
          },
          data: this.data,
          animationDuration: animationDuration
        });
      }

      return series;
    },
    getTooltip(type) {
      var indicator = this.indicator;
      var tooltipPosition = this.tooltipPosition;
      var widthMax = this.widthMax;

      return {
        show: true,
        trigger: "item",
        textStyle: {
          color: "#fff",
          fontFamily: "db_heaventregular",
          fontSize: 20
        },
        formatter(params) {
          var output =
            `<span style="font-size:18px;">${params.name}</span>` + "<br/>";
          for (let index = 0; index < indicator.length; index++) {
            let i = 0;
            let marker = `<div style="overflow: hidden;line-height: 1;"><span style="line-height: 1;display:inline-block;margin-right:4px;border-radius:10px;width:7px;height:7px;background-color:${params.color};border:1px rgba(255,255,255,.7) solid;"></span>`;

            if (index > 0) {
              i = 1 + (indicator.length - (1 + index));
            }

            let name = `<span style="line-height: .8;font-size:16px;">${indicator[i].name}</span>`;
            let value = Number(params.value[i]);

            output +=
              marker +
              name +
              `<span style="line-height: 1.25;float:right;margin-left:20px;font-size:18px;color:#fff;font-weight:700">${value.toFixed(
                1
              )}</span>` +
              "</div>";
          }
          return output;
        },
        position(point) {
          if (type === "data") {
            return widthMax < 768
              ? [point[0] - 100, point[1] + 20]
              : [
                  `${point[0] + tooltipPosition[0]}`,
                  point[1] + tooltipPosition[1]
                ];
          }

          if (type === "dataAvg") {
            return widthMax < 768 ? [point[0] - 100, point[1] + 20] : point;
          }
        }
      };
    }
  }
};
</script>
